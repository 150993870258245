import React, { Fragment } from 'react';
import {WelComeToDashboard} from './loan-enquiry/index';


export default (props) => {
    console.log(props);
    let params = {}, queries, temp, i, l, queryString;
    queryString = props.location.search;
    if (queryString.indexOf('?') != -1) {
        queryString = decodeURIComponent(queryString.substring(queryString.indexOf('?') + 1));
        queries = queryString.split("&");
        for (i = 0, l = queries.length; i < l; i++) {
            temp = queries[i].split('=');
            if (temp.length > 2) {
                params[temp[0]] = `${temp[1]}=`;
            } else {
                params[temp[0]] = temp[1];
            }
        }
    }

    console.log(params);

    let propObj = {};

    propObj.funnelData = {};

    propObj.funnelData.bookAppointment = {};

    propObj.funnelData.bookAppointment.ref = '';

    if (Object.keys(params).length != 0) {
        if (params.token) {
            propObj.funnelData.bookAppointment.ref = params.token;
        }
    }

    return(
        <div>
            <WelComeToDashboard {...propObj}/>
        </div>
    )
}